import { useWeb3Context } from "components/Layout";
import Mint from "components/Mint/Mint";
import React, { useState } from "react";
import {
  SALE_STAGE,
} from "utils/config";
import { ContractTypes } from "utils/contracts";
import { useContract } from "utils/web3";
import MainContent from "components/MainContent";

// SALE_STAGE = 'phase1' | 'phase2' | 'closed' | 'public'

export default function Index() {
  const { address, setNotification } = useWeb3Context();
  const [balance, setBalance] = useState<number | undefined>();
  const [nftContract] = useContract(
    ContractTypes.MINT_NFT_CONTRACT
  );

  return (
    <div className="text-center">
      <MainContent balance={balance} />

      {address && nftContract && (
        <Mint
          saleStage={SALE_STAGE}
          address={address}
          setNotification={setNotification}
          balance={balance}
          setBalance={setBalance}
        />
      )}
    </div>
  );
}
