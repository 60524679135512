import React from "react";
import ConnectButton from "components/ConnectButton/ConnectButton";
import { useWeb3Context } from "components/Layout";
import { SALE_STAGE, T_SALE_STAGE } from "utils/config";

type Props = {
  balance?: number;
  overrideSaleStage?: T_SALE_STAGE;
};

export default function MainContent({ balance, overrideSaleStage }: Props) {
  const OVERRIDE_SALE_STAGE = overrideSaleStage ?? SALE_STAGE;

  const { address } = useWeb3Context();

  if (OVERRIDE_SALE_STAGE === T_SALE_STAGE.CLOSED) {
    return (
      <div className="mt-24 text-center px-2 font-rainshowCondensed">
        <p>The Galaxy Emblem mint is SOLD OUT.</p>
      </div>
    );
  }

  return (
    <>
      <h1 className="text-3xl sm:text-4xl text-center px-6">
        {SALE_STAGE === T_SALE_STAGE.PUBLIC
          ? "Welcome to the Galaxy Emblem public mint!"
          : "Welcome to the Galaxy Emblem private mint!"}
        <br />
      </h1>

      {balance !== undefined && Number(balance) > 0 && (
        <>
          <p className="mt-4 text-center text-xl max-w-md mx-auto">
            You now posses the power of the Galaxy Emblem and can customize the
            Exodia dashboard!
            {/* <br />
            Learn more about the Exodia Emblems by joining our{" "}
            <a
              className="underline"
              href="https://discord.gg/exodia-io"
              rel="noreferrer noopener nofollow"
            >
              Discord channel.
            </a> */}
          </p>
        </>
      )}

      {!address && (
        <div className="mt-12 w-full text-center px-6">
          <ConnectButton />
        </div>
      )}
    </>
  );
}
