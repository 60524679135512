import axios from "axios";

export enum T_MERKLE_TYPE {
  al = "al",
  wl = "wl",
}

export type NftResult = {
  total: number;
  result: [
    {
      token_id: string;
    }
  ];
  status: string;
};

export async function fetchOwnerBatIds(address: string) {
  const { data } = await axios.get<NftResult>(`/api/bat_ids/${address}`);
  return data;
}

// Used for mints with only one merkle root
export async function fetchPresaleLeafAndProof(address: string) {
  const { data } = await axios.get<{ leaf: any; proof: string[] }>(
    `/api/presale-merkle-proof/${address}`
  );

  return data;
}

// Used for mints with multiple merkle roots in presale
export async function fetchPresaleMerkleData(address: string) {
  const { data } = await axios.get<{
    leaf: any;
    proof: string[];
    type: T_MERKLE_TYPE;
  }>(`/api/presale-merkle-proofs/${address}`);
  console.log('in api call', data)

  return data;
}
