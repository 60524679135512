import Dropdown from "components/Dropdown/Dropdown";
import { useWeb3Context } from "components/Layout";
import Spinner from "components/Spinner/Spinner";
import { serializeError } from "eth-rpc-errors";
import { BigNumber, ethers } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { fetchPresaleMerkleData, T_MERKLE_TYPE } from "utils/apis";
import {
  MAX_MINT_PRESALE,
  MAX_MINT_PUBLIC,
  SALE_STAGE,
  T_SALE_STAGE,
} from "utils/config";
import { ContractTypes, DEFAULT_MINT_PRICE } from "utils/contracts";
import { getUserError, showTransactionNotifications } from "utils/utilities";
import { useContract } from "utils/web3";
import Button from "../Button/Button";
import { NotificationInterface } from "../Notification/Notification";

interface Props {
  saleStage: T_SALE_STAGE;
  address: string;
  balance?: number;
  setBalance: React.Dispatch<React.SetStateAction<number | undefined>>;
  setNotification: (
    props: NotificationInterface
  ) => React.Dispatch<React.SetStateAction<NotificationInterface | undefined>>;
}

function Mint({
  saleStage,
  address,
  balance,
  setBalance,
  setNotification,
}: Props) {
  const [nftContract] = useContract(ContractTypes.MINT_NFT_CONTRACT);
  const { isProcessing, setIsProcessing } = useWeb3Context();
  const [whitelistProof, setWhitelistProof] = useState<string[]>();
  const [mintType, setMintType] = useState<T_MERKLE_TYPE>();
  const [selected, setSelected] = useState<string>("2");
  const [txHash, setTxHash] = useState<string>("");
  const [mintPrice, setMintPrice] = useState<number>(DEFAULT_MINT_PRICE);
  const [mintedAmount, setMintedAmount] = useState<number>(0);
  const [mintValue, setMintValue] = useState<number>(
    (mintPrice * 1000000 * Number(selected)) / 1000000
  );
  const [buttonMessage, setButtonMessage] = useState<string>(
    `MINT - ${mintValue} ETH`
  );

  useEffect(() => {
    if (!nftContract || !address) return;

    const getMintPrice = async () => {
      try {
        const _mintPrice = (await nftContract.mintPrice()) as BigNumber;
        const mintPrice = Number(ethers.utils.formatEther(_mintPrice));
        setMintPrice(mintPrice);
        setRealMintValue();
      } catch (error) {
        console.log(error);
      }
    };
    getMintPrice();
  }, [address, nftContract, selected]);

  useEffect(() => {
    if (!nftContract || !address) return;

    const fetchInitialData = async () => {
      try {
        const query =
          saleStage === T_SALE_STAGE.PRESALE
            ? fetchPresaleMerkleData
            : fetchPresaleMerkleData;
        const [{ proof, type }, balance, mintedAmount] = await Promise.all([
          query(address),
          nftContract.balanceOf(address),
          nftContract.numberMinted(address),
        ]);
        setWhitelistProof(proof);
        setMintType(type);
        setBalance(balance.toNumber());
        setMintedAmount(mintedAmount.toNumber());
        setRealMintValue(mintedAmount.toNumber(), type, proof);
      } catch (error) {
        console.error(error);
        setWhitelistProof([]);
      }
    };

    fetchInitialData();
  }, [address, nftContract, setBalance, setWhitelistProof, saleStage]);

  const setRealMintValue = async (
    _mintedAmount?: number,
    _type?: T_MERKLE_TYPE,
    _whitelistProof?: string[]
  ) => {
    if (!nftContract) return;
    const amountMinted = _mintedAmount ?? mintedAmount;
    const mintingType = _type ?? mintType;
    const wlProof = _whitelistProof ?? whitelistProof;

    try {
      if (
        wlProof !== undefined &&
        wlProof.length > 0 &&
        mintingType === T_MERKLE_TYPE.al
      ) {
        setMintValue(0);
        const message =
          amountMinted + Number(selected) <= 2 ? "FREE MINT" : "MINT ERROR";
        setButtonMessage(message);
      } else if (
        wlProof !== undefined &&
        wlProof.length > 0 &&
        mintingType === T_MERKLE_TYPE.wl &&
        saleStage === T_SALE_STAGE.PRESALE
      ) {
        const realMintValue =
          amountMinted + Number(selected) === 1
            ? 0
            : amountMinted + Number(selected) === 2
            ? 0.01
            : 0.02;
        setMintValue(realMintValue);
        const message =
          realMintValue === 0
            ? "FREE MINT"
            : realMintValue === 0.01
            ? `MINT - ${realMintValue} ETH`
            : "MINT ERROR";
        setButtonMessage(message);
      } else if (saleStage === T_SALE_STAGE.PRESALE) {
        const value = (mintPrice * 1000000 * Number(selected)) / 1000000;
        setMintValue(value);
        setButtonMessage(
          amountMinted + Number(selected) > 2
            ? "MINT ERROR"
            : `MINT - ${value} ETH`
        );
      } else {
        setMintValue(0);
        const message =
          amountMinted + Number(selected) <= 2 ? "FREE MINT" : "MINT ERROR";
        setButtonMessage(message);
      }
    } catch (e: Error | unknown | any) {
      const error: any = serializeError(e);
      console.error(error);
    }
  };

  const handleMint = async (amount: string) => {
    if (!nftContract) return;

    let transaction;
    try {
      if (
        whitelistProof !== undefined &&
        whitelistProof.length > 0 &&
        mintType === T_MERKLE_TYPE.al
      ) {
        transaction = await nftContract.mintAL(amount, whitelistProof, {
          from: address,
        });
      } else if (
        whitelistProof !== undefined &&
        whitelistProof.length > 0 &&
        mintType === T_MERKLE_TYPE.wl &&
        saleStage === T_SALE_STAGE.PRESALE
      ) {
        transaction = await nftContract.mintWL(amount, whitelistProof, {
          from: address,
          value: ethers.utils.parseEther(mintValue.toString()),
        });
      } else if (saleStage === T_SALE_STAGE.PRESALE) {
        transaction = await nftContract.mintSkipList(amount, {
          from: address,
          value: ethers.utils.parseEther(mintValue.toString()),
        });
      } else if (saleStage === T_SALE_STAGE.PUBLIC) {
        transaction = await nftContract.mint(amount, {
          from: address,
        });
      }

      await showTransactionNotifications(
        setNotification,
        setIsProcessing,
        transaction
      );

      setTxHash(transaction.hash);
      setBalance(Number(balance) + Number(amount));
    } catch (e: Error | unknown | any) {
      const error: any = serializeError(e);
      console.error(error);
      const message =
        error?.data?.message ||
        error?.data?.originalError?.error?.message ||
        "An error has occured";

      if (e?.code === "INSUFFICIENT_FUNDS") {
        setNotification({
          type: "error",
          title: "Error",
          message: `You don't have enough eth to mint`,
        });
      } else if (e?.code === 4001) {
        setNotification({
          type: "error",
          title: "Error",
          message: `Transaction rejected by user`,
        });
      } else {
        setNotification({
          type: "error",
          title: "Error",
          message: getUserError(message),
        });
      }
    }
  };

  const getDropdownOptions = useCallback(() => {
    const fillDropdown = (arr: Array<number>) =>
      arr.fill(1).map((x: number, y: number) => x + y);

    switch (saleStage) {
      case "PRESALE":
        return fillDropdown(Array(MAX_MINT_PRESALE));
      default:
        return fillDropdown(Array(MAX_MINT_PUBLIC));
    }
  }, [saleStage]);

  return (
    <div className="flex flex-col align-center justify-center mt-12 px-6">
      {whitelistProof !== undefined &&
        whitelistProof.length === 0 &&
        SALE_STAGE === T_SALE_STAGE.PRESALE && (
          <>
            <p className="text-center text-xl text-white-200">
              It seems that you aren&apos;t on the mint list.
              <br />
              You can bypass the mint list by minting for 0.01 ETH.
              <br />
            </p>
          </>
        )}
      {whitelistProof === undefined ? (
        <Spinner />
      ) : (
        // balance !== undefined &&
        // Number(balance) < Number(MAX_MINT_PRESALE) &&
        (SALE_STAGE === T_SALE_STAGE.PRESALE ||
          SALE_STAGE === T_SALE_STAGE.PUBLIC) && (
          <>
            <p className="text-center text-xl px-6">
              Please select the amount you&apos;d like to mint
            </p>

            <Dropdown
              options={getDropdownOptions()}
              selected={selected}
              setSelected={setSelected}
            />

            <Button
              disabled={isProcessing}
              buttonType="ActionButton"
              className="w-full mt-6 text-xl max-w-xs mx-auto whitespace-nowrap"
              onClick={() => handleMint(selected.toString())}
            >
              {buttonMessage}
            </Button>

            {txHash && (
              <>
                <div className="text-lg text-center">
                  <a
                    className="relative top-8 hover:underline"
                    href={`https://etherscan.io/tx/${txHash}`}
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    View on etherscan
                  </a>
                </div>

                <p className="mt-16">
                  Earn 150$ by refering your friends to Exodia Premium:
                </p>

                {/* TODO don't wrap buttons in <a> tag */}
                <div className="mt-4">
                  <a href="https://sharemint.xyz/exodia-premium/affiliate">
                    <Button>Refer Subscribers and Get Paid</Button>
                  </a>
                </div>

                {/* <div className="mt-4">
                  <a href="https://app.heymint.xyz/exodia-emblem">
                    <Button>Get Early Access</Button>
                  </a>
                </div> */}
              </>
            )}
          </>
        )
      )}
    </div>
  );
}

export default Mint;
